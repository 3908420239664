import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Modal from '@cx/couturier/labs/Modal'
import { userDeactivated } from '../../store/actions'

const redirectToWelcomeBack = () => {
  window.location.href = '/welcome-back'
}

const WelcomeBackModal = ({ deactivatedModal, dispatchUserDeactivated }) => (
  <Modal
    confirmText="Let's Go!"
    contentLabel="Welcome Back modal"
    description="Welcome Back"
    isOpen={deactivatedModal}
    name="deactivated-user"
    onClose={() => {
      dispatchUserDeactivated()
    }}
    onConfirm={redirectToWelcomeBack}
    onRequestClose={() => {}}
    size="medium"
    titleText="Welcome back!"
  >
    <p>It’s been a while since you logged in, so we need to confirm a few details and reset your password — it should only take 2 minutes.</p>
  </Modal>
)

const mapStateToProps = (state) => ({
  deactivatedModal: state.submit.deactivatedModal
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dispatchUserDeactivated: userDeactivated
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeBackModal)
