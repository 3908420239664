import * as types from '../types'

export const initialState = {
  text: 'Log In',
  wait: false,
  disabled: true,
  lockedOut: false,
  resetModal: false,
  stagedModal: false,
  deactivatedModal: false,
  badBrowser: false,
  responseErrorModal: false,
  isIntuneManagedBrowser: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.UPDATE_FORM_SUBMIT_TEXT:
      return { ...state, text: payload }
    case types.UPDATE_FORM_SUBMIT_DISABLED:
      return { ...state, disabled: payload }
    case types.UPDATE_FORM_SUBMIT_WAIT:
      return { ...state, wait: payload }
    case types.UPDATE_FORM_LOCKED_OUT:
      return { ...state, lockedOut: !state.lockedOut }
    case types.SET_FORM_RESET_MODAL:
      return { ...state, resetModal: true }
    case types.CLEAR_FORM_RESET_MODAL:
      return { ...state, resetModal: false }
    case types.UPDATE_FORM_STAGED:
      return { ...state, stagedModal: !state.stagedModal }
    case types.UPDATE_FORM_DEACTIVATED:
      return { ...state, deactivatedModal: !state.deactivatedModal }
    case types.ACKNOWLEDGE_BAD_BROWSER:
      return { ...state, badBrowser: false }
    case types.SET_RESPONSE_ERROR_MODAL:
      return { ...state, responseErrorModal: true }
    default:
      return state
  }
}
