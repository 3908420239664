import * as types from '../types'

export const initialState = {
  username: '',
  usernameError: '',
  usernameTouched: false,
  password: '',
  passwordError: '',
  passwordTouched: false,
  hasBookmarkError: false,
  error: '',
  isComplete: false,
  sessionToken: '',
  greetingMessageTime: '',
  greetingMessageSite: ''
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.UPDATE_FORM_USERNAME:
      return { ...state, username: payload, usernameError: '', passwordError: '', usernameTouched: true }
    case types.UPDATE_FORM_USERNAME_ERROR:
      return { ...state, usernameError: payload, usernameTouched: true }
    case types.UPDATE_FORM_PASSWORD:
      return { ...state, password: payload, passwordError: '', usernameError: '', passwordTouched: true }
    case types.UPDATE_FORM_PASSWORD_ERROR:
      return { ...state, passwordError: payload, usernameError: payload, usernameTouched: true, passwordTouched: true }
    case types.UPDATE_FORM_BOOKMARK_ERROR:
      return { ...state, hasBookmarkError: true }
    case types.UPDATE_FORM_ERROR:
      return { ...state, error: payload }
    case types.FORM_COMPLETE_POST_SUCCESS:
      return { ...state, isComplete: true, sessionToken: payload }
    case types.UPDATE_GREETING_MESSAGE:
      return { ...state, greetingMessageTime: payload }
    default:
      return state
  }
}
