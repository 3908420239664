export const initialState = {
  title: 'Login | Northwestern Mutual',
  nmUniqueId: 'no_user',
  supportHours: {},
  clearSession: false,
  logoutUrls: {
    urls: {},
    nmisLogoutUrl: {}
  }
}

export default (state = initialState) => state
