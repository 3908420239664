import { combineReducers } from 'redux'

import meta from './meta'
import form from './form'
import submit from './submit'
import track from './track'

export default combineReducers({
  meta,
  form,
  submit,
  track
})
