import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TextField from '@cx/couturier/TextField'
import { updateUsername, validateUsername } from '../store/actions'

class Username extends PureComponent {
  constructor(props) {
    super(props)
    this.username = ''
    this.setRef = this.setRef.bind(this)
  }

  componentDidMount() {
    try {
      // REMOVES REDIRECT QUERY PARAM
      const newUrl = window.location.href.split('?')[0]

      window.history.pushState('', '', newUrl)
      const { username, dispatchUpdateUsername } = this.props
      if (!username) {
        // focus on username field if not populated
        this.username.focus()
        // if the prop has no value, but the input does, an autofill happened.  Let's reconcile the state.
        if (this.username.value) {
          dispatchUpdateUsername({ target: { value: this.username.value } })
        }
      }
    } catch (err) {
      //
    }
  }

  componentDidUpdate(prevProps) {
    const { username } = this.props
    if (!username && username !== prevProps.username) {
      this.username.focus()
    }
  }

  setRef(inputElement) {
    this.username = inputElement
  }

  render() {
    const { usernameError, dispatchUpdateUsername } = this.props

    return (
      <div className="username row">
        <TextField
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          autoFocus
          error={usernameError === true || usernameError.length > 0}
          fullWidth
          id="username"
          inputRef={this.setRef}
          label="Username"
          onChange={dispatchUpdateUsername}
          value={this.username ? this.username.value : ''}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dispatchUpdateUsername: updateUsername,
      dispatchValidateUsername: validateUsername
    },
    dispatch
  )

const mapStateToProps = (state) => ({
  username: state.form.username,
  usernameError: state.form.usernameError,
  formError: state.form.error
})

export default connect(mapStateToProps, mapDispatchToProps)(Username)
