import styled, { css } from 'styled-components'
import { Row, Col } from '@cx/couturier/Grid'
import Button from '@cx/couturier/Button'
import { Header, Footer, Body } from '@cx/couturier/labs/ImmersiveFlow'

export const FooterRowMobile = styled(Row)`
  && {
    width: 279px;
  }
`

export const IFBodyNoPadding = styled(Body)`
  min-height: max-content;
  & > div > div {
    padding-bottom: 0;
  }
`

export const ResponsiveButton = styled(Button)`
  ${({ theme: { media } }) =>
    media.smallMedium(css`
      width: 100%;
    `)};
`

export const HeaderWithLine = styled(Header)`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutral.nickel};
  svg {
    height: 100%;
  }
`

export const FooterWithLine = styled(Footer)`
  && {
    & > div > div > div:first-child {
      display: none;
    }

    background-color: ${({ isAltColor, theme }) => (isAltColor ? theme.colors.primary.nmBlue : theme.colors.neutral.silverDolar)};
    border-color: ${({ theme }) => theme.colors.neutral.nickel};
    border-top: 1px solid ${({ isAltColor, theme }) => (isAltColor ? theme.colors.neutral.halfDollar : theme.colors.neutral.nickel)};
    box-shadow: none;
    /* color: ${({ isAltColor, theme }) => (isAltColor ? theme.colors.neutral.white : 'inherit')}; */
    height: auto;
    transition: background-color 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);

    p {
      color: ${({ isAltColor, theme }) => (isAltColor ? theme.colors.neutral.white : theme.colors.neutral.silverDolar)};
    }
    position: relative;
  }
`

export const HelperFont = styled.div`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral.silverDollar};
  ${({ theme: { media } }) =>
    media.smallMedium(css`
      font-size: 12px;
      line-height: 20px;
    `)};
`

export const FormattedForm = styled.form`
  width: 279px;
  @media (min-width: 768px) {
    min-width: 346px;
    max-width: 346px;
    width: 346px;
  }
`

export const ShowForMediumUp = styled.div`
  display: none;
  ${({ theme: { media } }) =>
    media.medium(css`
      display: block;
    `)}
`

export const ShowForSmallOnly = styled.div`
  display: none;
  ${({ theme: { media } }) =>
    media.smallMedium(css`
      display: block;
    `)}
`

export const CopyrightFont = styled.p`
  && {
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 20px;
    margin-top: ${({ marginTop }) => marginTop || '16px'};
    margin-bottom: ${({ marginBottom }) => marginBottom || '16px'};
    text-align: left;
    color: ${({ theme }) => theme.colors.neutral.silverDollar};
    @media (min-width: 768px) {
      text-align: left;
    }
  }
`

export const HoursFont = styled(CopyrightFont)`
  && {
    text-align: left;
    @media (min-width: 600px) {
      text-align: right;
    }
  }
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.dime};
`

export const MobileContainer = styled(Row)`
  align-items: center;
  bottom: 0;
  /* position: fixed; */
  justify-content: center;
`

export const MainText = styled.h1`
  && {
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 2rem;
    margin-bottom: 16px;
    @media (min-width: 768px) {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }
`

export const SubText = styled.strong`
  && {
    font-weight: 200;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 14px;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
`

export const AccessableText = styled.span`
  font-size: 0px;
  position: absolute;
`

export const PwdResetLink = styled.a`
  && {
    color: #3157e1;
  }
`
export const AppStoreImg = styled.img`
  height: 35px;
  width: 103px;
`

export const QRCodeImg = styled.img`
  height: 88px;
  margin: 0 0.9rem;
`

export const AppStoreContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.3rem;
`

export const QRCodeContainer = styled.div`
  display: flex;
  align-items: center;
`

export const AppDownloadContainer = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 1rem 1.7rem;
  ${({ theme: { media } }) =>
    media.mediumLarge(css`
      margin: 0.7rem 0 0 -5.2rem;
    `)};
`

export const AppDownloadText = styled.p`
  && {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #1f2d46;

    ${({ theme: { media } }) =>
      media.large(css`
        > span {
          &:nth-child(2) {
            margin-left: 0.8rem;
          }
        }
      `)};
    ${({ theme: { media } }) =>
      media.mediumLarge(css`
        font-size: 12px;
        line-height: 20px;
      `)};
    ${({ theme: { media } }) =>
      media.smallMedium(css`
        font-size: 12px;
        line-height: 20px;
        > span {
          &:first-child {
            margin-left: 1.2rem;
          }
          &:last-child {
            margin-left: 4.3rem;
          }
        }
      `)}
  }
`

export const AppDownloadRow = styled(Row)`
  && {
    height: 140px;
    margin: 7% 0 0 -25%;
    background: #f7f9fc;
    border: solid #e5e9f1;
    border-radius: 4px;
    ${({ theme: { media } }) =>
      media.large(css`
        width: 728px;
        height: 144px;
        margin: 3rem 0rem 0rem -9rem;
      `)};

    ${({ theme: { media } }) =>
      media.smallMedium(css`
        width: 275px;
        margin: 3rem 0rem;
        height: 210px;
      `)};

    ${({ theme: { media } }) =>
      media.mediumLarge(css`
        height: 145px;
        width: 490px;
        margin: 2.5rem -90px;
        flex-direction: column;
      `)};
  }
`

export const AppDownloadTextCol = styled(Col)`
  && {
    padding: 2.5rem 0rem 0rem 2.8rem;
    width: 300px;
    display: flex;
    flex-direction: column;
    ${({ theme: { media } }) =>
      media.mediumLarge(css`
        display: inline;
        padding: 1.8rem 5.4rem 0rem 2rem;
      `)};
    ${({ theme: { media } }) =>
      media.smallMedium(css`
        display: inline;
        margin: -1.1rem 0 -0.7rem -2rem;
      `)};
  }
`
