import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ResponsiveButton } from './component-styles'
import { postForm } from '../store/actions'

const Submit = ({ text, wait, disabled, dispatchPostForm }) => (
  <div className="submit">
    <ResponsiveButton alt="Submit Login" disabled={disabled} id="login" onClick={dispatchPostForm} title="Submit Login" type="submit" working={wait}>
      {text}
    </ResponsiveButton>
  </div>
)

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dispatchPostForm: postForm
    },
    dispatch
  )

const mapStateToProps = (state) => ({
  text: state.submit.text,
  wait: state.submit.wait,
  disabled: state.submit.disabled
})

export default connect(mapStateToProps, mapDispatchToProps)(Submit)
