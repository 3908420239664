import React from 'react'
import { connect } from 'react-redux'
import Modal from '@cx/couturier/labs/Modal'
import { bindActionCreators } from 'redux'
import { acknowledgeBadBrowser } from '../../store/actions'

const modalTitle = 'It looks like we don’t support your browser.'
const OutOfDateModal = ({ badBrowser, dispatchAcknowledge }) => (
  <Modal
    confirmText="I Understand"
    contentLabel="Browser out of date modal"
    description="Browser out of date"
    isOpen={badBrowser}
    name="browser-out-of-date"
    onConfirm={dispatchAcknowledge}
    titleText={modalTitle}
    variant="error"
  >
    <p>
      We noticed you are using Internet Explorer. This browser is no longer supported. For the best experience possible, please use Edge, Chrome,
      Firefox or Safari.
    </p>
  </Modal>
)

const mapStateToProps = (state) => ({
  badBrowser: state.submit.badBrowser
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dispatchAcknowledge: acknowledgeBadBrowser
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(OutOfDateModal)
