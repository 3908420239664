import React from 'react'
import { Provider, connect } from 'react-redux'
import Base from '@cx/couturier/Base'
import { Container } from '@cx/couturier/labs/ImmersiveFlow'
import { Row, Col } from '@cx/couturier/Grid'
import styled from 'styled-components'
import { CompanyLogoModified } from 'cxid-common-components/util-components'
import _map from 'lodash/map'
import config from '../utils/config'
import Greeting from './greeting'
import Username from './username'
import Password from './password'
import ErrorBanner from './error-banner'
import Submit from './submit'
import LockedOutModal from './modals/locked-modal'
import StagedModal from './modals/staged-modal'
import WelcomeBackModal from './modals/deactivated-user-modal'
import OutOfDateModal from './modals/browser-out-dated-modal'
import IntuneBrowserModal from './modals/browser-intune-modal'
import { AppDownloadBanner } from './app-download-banner'
import { MobileRelativeFooter, StickyDesktopFooter } from './app-footer'
import { HelperFont, FormattedForm, ShowForMediumUp, ShowForSmallOnly, HeaderWithLine, IFBodyNoPadding } from './component-styles'

const HiddenIframe = styled.iframe`
  display: none;
`

const LogoutFrames = (logoutUrls) => {
  const { urls: lUrls, nmisLogoutUrl } = logoutUrls
  const randomizeUrl = `?${Math.floor(Math.random() * 100000000)}${Math.floor(Math.random() * 100000000)}`
  const urls = _map(lUrls, (url) => url + randomizeUrl)

  return (
    <div className="iframe-container">
      {urls.map((url) => (
        <HiddenIframe key={url} src={`${url}`} title={url} />
      ))}
      <HiddenIframe key={nmisLogoutUrl} src={`${nmisLogoutUrl}`} title={nmisLogoutUrl} />
    </div>
  )
}

const App = ({ telephone, hours, clearSession, urls, isLunaLiteEnabled, intune, badBrowser }) => (
  <Base luna={isLunaLiteEnabled}>
    <LockedOutModal />
    <StagedModal />
    <WelcomeBackModal />

    {badBrowser ? <OutOfDateModal /> : null}
    {intune ? <IntuneBrowserModal /> : null}

    <noscript>
      <OutOfDateModal />
    </noscript>
    <Container relativeFooterMobileOnly>
      <HeaderWithLine
        PrefixComponent={
          <>
            <ShowForMediumUp alt="Northwestern Mutual Logo" aria-label="Northwestern Mutual Logo" role="img">
              <CompanyLogoModified full />
            </ShowForMediumUp>
            <ShowForSmallOnly alt="Northwestern Mutual Logo" aria-label="Northwestern Mutual Logo" role="img">
              <CompanyLogoModified />
            </ShowForSmallOnly>
          </>
        }
      />
      <IFBodyNoPadding>
        <FormattedForm autoComplete="off" method="POST">
          <Row>
            <Col medium={12} small={12}>
              <Greeting />
              <ErrorBanner />
              <Username />
              <Password />
              <HelperFont className="forgot" marginBottom="40px">
                Forgot your{' '}
                <a alt="Forgot Username" href={config.client.username} id="forgot-username">
                  username
                </a>{' '}
                or{' '}
                <a alt="Forgot Password" href={config.client.password} id="forgot-password">
                  password
                </a>
                ?
              </HelperFont>

              <Submit />

              <HelperFont className="sign-up" marginTop="40px">
                Don’t have an online account?{' '}
                <a alt="Sign up" href={config.client.registration} id="register">
                  Register
                </a>
              </HelperFont>
            </Col>
          </Row>
          <AppDownloadBanner />
          <MobileRelativeFooter hours={hours} telephone={telephone} />
        </FormattedForm>
      </IFBodyNoPadding>
      <StickyDesktopFooter hours={hours} telephone={telephone} />
      {clearSession ? <LogoutFrames {...urls} /> : null}
    </Container>
  </Base>
)

const mapStateToProps = (state) => ({
  telephone: state.meta.supportHours.telephone,
  badBrowser: state.submit.badBrowser,
  intune: state.submit.isIntuneManagedBrowser,
  clearSession: state.meta.clearSession,
  hours: state.meta.supportHours.hours,
  urls: state.meta.logoutUrls,
  isLunaLiteEnabled: state.meta.isLunaLiteEnabled
})
const ConnectedApp = connect(mapStateToProps)(App)

const ProviderComponent = ({ store }) => (
  <Provider store={store}>
    <ConnectedApp />
  </Provider>
)

ProviderComponent.displayName = 'ProviderComponent'

export default ProviderComponent
