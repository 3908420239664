import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Modal from '@cx/couturier/labs/Modal'
import { userlockedOut } from '../../store/actions'

const lockedModal = ({ lockedOut, dispatchUserlockedOut }) => (
  <Modal
    description="We’ve temporarily locked your account."
    isOpen={lockedOut}
    name="locked-out"
    onClose={dispatchUserlockedOut}
    titleText="We’ve temporarily locked your account."
  >
    <p>
      For your security, your account has been locked for the next two hours. To immediately regain access to your account, please call Customer
      Service.
    </p>
  </Modal>
)

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dispatchUserlockedOut: userlockedOut
    },
    dispatch
  )

const mapStateToProps = (state) => ({
  lockedOut: state.submit.lockedOut
})

export default connect(mapStateToProps, mapDispatchToProps)(lockedModal)
