/* eslint-disable no-undef */
export const checkStatus = (response) => {
  if (!response.ok) {
    // status in the range 200-299 or not
    const error = new Error(response.statusText || 'Status not OK')
    error.status = response.status

    return Promise.reject(error)
  }

  return response
}

export const parseJSON = (response) => response.json()

export default (url, options) => fetch(url, options).then(checkStatus).then(parseJSON)
