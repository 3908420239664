import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Modal from '@cx/couturier/labs/Modal'
import { userStaged } from '../../store/actions'

const redirectToPasswordReset = () => {
  window.location.href = '/password-reset'
}

const StagedModal = ({ stagedModal, dispatchUserStaged }) => (
  <Modal
    confirmText="Reset Password"
    contentLabel="Reset Password modal"
    description="Reset Password"
    isOpen={stagedModal}
    name="staged-user"
    onClose={() => {
      dispatchUserStaged()
    }}
    onConfirm={redirectToPasswordReset}
    onRequestClose={() => {}}
    size="medium"
    titleText="Welcome back!"
  >
    <p>
      We noticed it’s been a while since you logged in. Since your online account has been inactive for some time, we need you to reset your password
      to make sure your information stays secure.
    </p>
  </Modal>
)

const mapStateToProps = (state) => ({
  stagedModal: state.submit.stagedModal
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dispatchUserStaged: userStaged
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(StagedModal)
