import reporter from 'redux-reporter'

export default reporter(
  ({ type, payload }) => {
    try {
      if (window.heap) {
        window.heap.track(type, payload)
      }
    } catch (err) {
      if (window.newrelic) {
        window.newrelic.noticeError(err)
      }
    }
  },
  ({ meta = {} }) => meta.analytics
)
