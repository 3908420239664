import * as types from '../types'

export const initialState = {
  attempt: 1,
  textState: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_FORM_SUBMIT_ATTEMPS:
      return { ...state, attempt: state.attempt + 1 }
    case types.SHOW_PASSWORD:
      return { ...state, textState: action.payload.textState }
    default:
      return state
  }
}
