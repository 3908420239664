import React from 'react'
import { connect } from 'react-redux'
import Modal from '@cx/couturier/labs/Modal'

const IntuneBrowserModal = ({ intune }) => (
  <Modal description="Browser not supported." isOpen={intune} name="unsupport-browser-intune" onClose={() => {}} titleText="Browser not supported.">
    <p>
      This eSignature document is not currently supported on an Intune browser. <br /> Try accessing this document from your Northwestern Mutual
      connected non-mobile device.
    </p>
  </Modal>
)

const mapStateToProps = (state) => ({
  intune: state.submit.isIntuneManagedBrowser
})

export default connect(mapStateToProps)(IntuneBrowserModal)
