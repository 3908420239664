import React from 'react'
import { connect } from 'react-redux'
import InlineNotification from '@cx/couturier/InlineNotification'
import styled, { css } from 'styled-components'
import { bindActionCreators } from 'redux'
import { PwdResetLink } from './component-styles'
import { formPasswordError, setResponseErrorModal } from '../store/actions'

const styles = css`
  && {
    visibility: ${(props) => (props.isShown ? 'visible' : 'hidden')};
    min-height: ${(props) => (props.isShown ? 'auto' : '40px')};
    max-height: ${(props) => (props.isShown ? 'auto' : '40px')};
    margin-top: ${(props) => (props.isShown ? '24px' : '0px')};
    margin-bottom: ${(props) => (props.isShown ? '24px' : '0px')};

    padding-top: 1rem;
    padding-bottom: 1rem;

    .ui-inline-notification-icon {
      ${({ theme: { media } }) =>
        media.smallMedium(css`
          display: none;
        `)}
    }

    .ui-inline-notification-message {
      &,
      p {
        font-size: 14px;
        p:first-of-type {
          margin-bottom: 8px;
        }
      }
    }
  }
`

const Notification = styled(InlineNotification)`
  ${styles}
`

const NotificationPlaceholder = styled.div`
  ${styles}
`

const showError = (usernameError, passwordError, isResetWarning, responseErrorModal, hasBookmarkError) => {
  if (isResetWarning || usernameError.length > 0 || passwordError.length > 0 || responseErrorModal) {
    return { shouldShowError: true, errorType: 'error' }
  }

  if (hasBookmarkError) {
    return { shouldShowError: true, errorType: 'warning' }
  }

  return { shouldShowError: false }
}

const ErrorMessage = ({ usernameError, passwordError, isResetWarning, responseErrorModal, hasBookmarkError }) => {
  if (responseErrorModal) {
    return (
      <>
        Sorry, we&apos;re having trouble logging you in. Please try again or contact Customer Service at <a href="tel:+8669504644">(866) 950-4644</a>.
      </>
    )
  }
  if (isResetWarning) {
    return (
      <>
        Still having trouble? Try <PwdResetLink href="/password-reset">resetting your password</PwdResetLink> to log back in.
      </>
    )
  }
  if (usernameError) {
    return usernameError
  }
  if (passwordError) {
    return passwordError
  }
  if (hasBookmarkError) {
    return (
      <>
        <p>
          Please <a href="https://www.northwesternmutual.com/">update</a> your bookmarked link to easily log in to your account.
        </p>
        <p>Third Party Administrators please contact your advisor for online access.</p>
      </>
    )
  }

  return null
}
// Please update your bookmarked link to easily log in to your account.
// Third Party Administrators please contact your advisor for online access.

const ErrorBanner = ({
  usernameError,
  passwordError,
  isResetWarning,
  responseErrorModal,
  hasBookmarkError,
  dispatchSetResponseErrorModal,
  dispatchSetFormPasswordError
}) => {
  const { shouldShowError, errorType } = showError(usernameError, passwordError, isResetWarning, responseErrorModal, hasBookmarkError)

  React.useEffect(() => {
    if (passwordError) {
      dispatchSetFormPasswordError()
    }
    if (window.hasResponseErrorModal) {
      dispatchSetResponseErrorModal()
    }
  }, [])

  if (shouldShowError) {
    return (
      <Notification
        aria-atomic="true"
        aria-live="assertive"
        aria-relevant="all"
        canClose={false}
        hasBookmarkError={hasBookmarkError}
        id="error-box"
        isOpen
        isShown={shouldShowError}
        type={errorType}
      >
        <ErrorMessage
          hasBookmarkError={hasBookmarkError}
          isResetWarning={isResetWarning}
          passwordError={passwordError}
          responseErrorModal={responseErrorModal}
          usernameError={usernameError}
        />
      </Notification>
    )
  }

  return <NotificationPlaceholder />
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dispatchSetResponseErrorModal: setResponseErrorModal,
      dispatchSetFormPasswordError: formPasswordError
    },
    dispatch
  )

const mapStateToProps = (state) => ({
  usernameError: state.form.usernameError,
  passwordError: state.form.passwordError,
  isResetWarning: state.submit.resetModal,
  responseErrorModal: state.submit.responseErrorModal,
  hasBookmarkError: state.form.hasBookmarkError
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBanner)
