import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TextField from '@cx/couturier/TextField'
import EyeIcon from '@cx/couturier/icons/system/Eye'
import EyeSlashIcon from '@cx/couturier/icons/system/EyeSlash'
import { updatePassword, trackShowPassword } from '../store/actions'

class Password extends PureComponent {
  constructor(props) {
    super(props)
    this.updateShowPassword = this.updateShowPassword.bind(this)
    this.getEndAdornment = this.getEndAdornment.bind(this)
    this.setRef = this.setRef.bind(this)
    this.password = ''
    this.state = {
      showPassword: false
    }
  }

  componentDidMount() {
    const { username, password, dispatchUpdatePassword } = this.props
    if (username) {
      this.password.focus()
    }
    // if the prop has no value, but the input does, an autofill happened.  Let's reconcile the state.
    if (!password && this.password.value) {
      dispatchUpdatePassword({ target: { value: this.password.value } })
    }
  }

  componentDidUpdate(prevProps) {
    const { username, formError } = this.props
    if (formError && !prevProps.formError && username && username.length !== 0) {
      this.password.value = ''
      this.password.focus()
    }
  }

  setRef(inputElement) {
    this.password = inputElement
  }

  getEndAdornment() {
    const { showPassword } = this.state
    if (showPassword) {
      return <EyeSlashIcon className="clickable" onClick={this.updateShowPassword} />
    }

    return <EyeIcon className="clickable" onClick={this.updateShowPassword} />
  }

  updateShowPassword(event) {
    const { dispatchTrackShowPassword } = this.props
    const { showPassword } = this.state
    event.preventDefault()
    event.stopPropagation()
    dispatchTrackShowPassword(!showPassword ? 'SHOWN' : 'HIDDEN')
    this.setState({
      showPassword: !showPassword
    })
  }

  render() {
    const { passwordError, dispatchUpdatePassword } = this.props
    const { showPassword } = this.state

    return (
      <div className="password">
        <TextField
          aria-describedby={passwordError === true || passwordError.length > 0 ? 'error-box' : ''}
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          endAdornment={this.getEndAdornment}
          error={passwordError === true || passwordError.length > 0}
          fullWidth
          id="password"
          inputRef={this.setRef}
          label="Password"
          onChange={dispatchUpdatePassword}
          type={showPassword ? 'text' : 'password'}
          value={this.password ? this.password.value : ''}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dispatchUpdatePassword: updatePassword,
      dispatchTrackShowPassword: trackShowPassword
    },
    dispatch
  )

const mapStateToProps = (state) => ({
  username: state.form.username,
  password: state.form.password,
  passwordError: state.form.passwordError,
  formError: state.form.error
})

export default connect(mapStateToProps, mapDispatchToProps)(Password)
