/* eslint-disable import/prefer-default-export */
import React from 'react'
import {
  AppDownloadRow,
  AppStoreContainer,
  AppDownloadTextCol,
  AppDownloadContainer,
  QRCodeContainer,
  AppStoreImg,
  QRCodeImg,
  AppDownloadText
} from './component-styles'
import { ANDROID_HREF, IOS_HREF, PLAY_STORE_IMG, APP_STORE_IMG, QR_CODDE } from '../static/consts'

export const AppDownloadBanner = () => (
  <AppDownloadRow>
    <AppDownloadTextCol>
      <AppDownloadText>
        Download our app for fast, easy, and secure <span>access</span> <span>to</span> your accounts and more—
        <span>anytime</span>, anywhere.
      </AppDownloadText>
    </AppDownloadTextCol>

    <AppDownloadContainer>
      <AppStoreContainer>
        <a href={IOS_HREF}>
          <AppStoreImg alt="app store" src={APP_STORE_IMG} />
        </a>
        <a href={ANDROID_HREF}>
          <AppStoreImg alt="play store" src={PLAY_STORE_IMG} />
        </a>
      </AppStoreContainer>
      <QRCodeContainer>
        <QRCodeImg alt="QR code for app download" src={QR_CODDE} />
      </QRCodeContainer>
    </AppDownloadContainer>
  </AppDownloadRow>
)
