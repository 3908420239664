import loadScript from './load-script'

export default (callback) => {
  const isGoodBrowser = 'fetch' in window && 'Promise' in window && 'assign' in Object && typeof Object.values === 'function'
  if (isGoodBrowser) {
    callback()
  } else {
    loadScript('/login/assets/public/polyfills.min.js', callback)
  }
}
