import React from 'react'
import { Row, Col } from '@cx/couturier/Grid'
import config from '../utils/config'
import FloatingFooterLine from './floating-footer-line'
import { CopyrightFont, HoursFont, Divider, FooterRowMobile, FooterWithLine, AccessableText } from './component-styles'

const AccessabilityText = 'Our support hours are the following... '

export const MobileRelativeFooter = ({ telephone, hours }) => {
  const date = new Date()
  const year = date.getFullYear()

  return (
    <FooterRowMobile className="show-for-mobile">
      <Col small={12}>
        <CopyrightFont className="security-info" marginBottom="1.5rem" marginTop="40px">
          Learn more about{' '}
          <a alt="Security and Privacy" href={config.client.security}>
            Security and Privacy
          </a>
          .
        </CopyrightFont>
      </Col>
      <Col small={12}>
        <Divider />
      </Col>
      <Col medium={6} small={12}>
        <CopyrightFont marginTop="1.5rem">
          Copyright © {year} The Northwestern Mutual Life Insurance Company. All Rights Reserved. Northwestern Mutual is the marketing name for The
          Northwestern Mutual Life Insurance Company and its subsidiaries.
        </CopyrightFont>
      </Col>
      <Col medium={6} small={12}>
        <HoursFont>Contact Us - {telephone}</HoursFont>
        <HoursFont marginBottom="1.5rem">
          <AccessableText>{AccessabilityText}</AccessableText>
          {hours}
        </HoursFont>
      </Col>
    </FooterRowMobile>
  )
}

export const StickyDesktopFooter = ({ telephone, hours }) => {
  const date = new Date()
  const year = date.getFullYear()

  return (
    <Row className="hide-for-mobile">
      <FloatingFooterLine>
        <CopyrightFont className="security-info" marginBottom="0" marginTop="0">
          Learn more about{' '}
          <a alt="Security and Privacy" href={config.client.security}>
            Security and Privacy
          </a>
          .
        </CopyrightFont>
      </FloatingFooterLine>

      <FooterWithLine>
        <Col medium={6} small={10}>
          <CopyrightFont>
            Copyright © {year} The Northwestern Mutual Life Insurance Company. All Rights Reserved. Northwestern Mutual is the marketing name for The
            Northwestern Mutual Life Insurance Company and its subsidiaries.
          </CopyrightFont>
        </Col>
        <Col medium={6} small={10}>
          <HoursFont marginBottom="0">Contact Us - {telephone}</HoursFont>
          <HoursFont marginTop="0">
            <AccessableText>{AccessabilityText}</AccessableText>
            {hours}
          </HoursFont>
        </Col>
      </FooterWithLine>
    </Row>
  )
}
