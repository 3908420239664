import React from 'react'
import styled, { css } from 'styled-components'
import { Col } from '@cx/couturier/Grid'

const FloatingText = styled.div`
  margin-bottom: 1rem;
  padding-left: 2.5rem;
  width: 100%;
  margin-top: 1.5rem;

  ${({ theme: { media } }) =>
    media.mediumLarge(css`
      padding-left: 1.5rem;
    `)};

  /* 600px to medium breakpoint */
  @media all and (min-width: 37.5em) and (max-width: 48em) {
    padding-left: 1.125rem;
  }
`
const FloatingTextWrapper = ({ children }) => (
  <FloatingText>
    <Col>
      <Col medium={6} small={10}>
        {children}
      </Col>
    </Col>
  </FloatingText>
)

FloatingTextWrapper.displayName = 'FloatingTextWrapper'

export default FloatingTextWrapper
